import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LocationPage from './pages/LocationPage';
// index.js or App.js
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
// index.js or App.js
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
function App() {
  return (
    <Router>
      <Routes>
        {/* Set LocationPage as the component for both the root path and dynamic paths */}
        <Route path="/" element={<LocationPage />} />
        <Route path="/:location" element={<LocationPage />} />
      </Routes>
    </Router>
  );
}

export default App;
