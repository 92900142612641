import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import locationsData from '../data/locations.json';
import { BsWhatsapp } from 'react-icons/bs';
import ScrollToTopButton from '../components/ScrollToTopButton';

const LocationPage = () => {
    const { location } = useParams();
    const canonicalUrl = `https://online-react-js.sivasoft.in/${location}`;
    // If there is no location, show a message with a link instead of the rest of the page
    if (!location) {
        return (
            <div style={{ textAlign: 'center', marginTop: '20px' }}>
                <h2>Visit <a href="https://sivasoft.in">https://sivasoft.in</a> for more details</h2>
            </div>
        );
    }

    // Find the location data matching the slug
    const locationData = locationsData.find(
        (loc) =>
            loc.slug.training === location ||
            loc.slug.course === location ||
            loc.slug.classes === location
    );

    // Return a not found message if location data is missing for a specific URL
    if (!locationData) return <div>Page not found</div>;

    // Determine the meta title, description, and keywords based on the slug
    let title = locationData.title.training;
    let description = locationData.description.training;
    let keywords = locationData.keywords.training;
    let type = locationData.type.training;

    // Update meta fields based on the specific URL parameter (location)
    if (locationData.slug.course === location) {
        title = locationData.title.course;
        description = locationData.description.course;
        keywords = locationData.keywords.course;
        type = locationData.type.course;
    } else if (locationData.slug.classes === location) {
        title = locationData.title.classes;
        description = locationData.description.classes;
        keywords = locationData.keywords.classes;
        type = locationData.type.classes;
    }
    // JSON-LD structured data object with dynamic title and URL
    const schemaData = {
        "@context": "http://schema.org",
        "@type": "Product",
        "name": title,
        "aggregaterating": {
            "@type": "AggregateRating",
            "ratingCount": 219560,
            "bestRating": "5",
            "ratingValue": "4.98",
            "worstRating": "0"
        },
        "url": canonicalUrl
    };
    return (
        <div>
            <Helmet>
                <link rel="canonical" href={canonicalUrl} />
                <title>{title}</title>
                <meta name="description" content={description} />
                <meta name="keywords" content={keywords} />
                <script type="application/ld+json">
                    {JSON.stringify(schemaData)}
                </script>
            </Helmet>

            <div className="header-top">
                <span className='text-white'> 🎉 12th Anniversary Offer! Get 50% off all courses 🎉
                </span>
            </div>
            {/* Header */}
            <header className="bg-danger text-white py-3">
                <div className="container">
                    <div className='row'>
                        <div className='col-lg-8'>
                            <h2>Siva Soft Online {type} - {locationData.city}</h2>
                            <h6 className="lead">{title}</h6>
                        </div>
                        <div className='col-lg-4 text-center'>
                            <img src="/images/p-3.png" width={200} height={100} alt={keywords} />
                        </div>
                    </div>

                </div>
            </header>
            <div className="mt-4 mb-4">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-4">
                            <div className="logo float-lg-left text-center">
                                <a href="https://sivasoft.in"
                                    rel="noopener noreferrer">
                                    <img src="/images/sivasoft-logo.png"
                                        alt={keywords}
                                        className="sivasoft-logo" width={40} height={40} />
                                    <span className="sivasoft-text ms-2">Siva Soft</span>
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4">
                            <div className="text-center">
                                <div className="cont">
                                    <p> Need Help ? call us free </p> <span> <i className="bi bi-whatsapp"> </i> +91 63029 64834</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4  mt-20 mt-lg-0">
                            <div className="button float-lg-right text-center">
                                <a href="https://forms.gle/ygt3scEY32hvnrxz6"
                                    target="_blank" rel="noopener noreferrer"
                                    className="main-btn">Apply For Course </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <hr />
            { /* Navbar */}
            <nav className="navbar navbar-expand-lg navbar-light">
                <div className="container">
                    <a className="navbar-brand" href="https://sivasoft.in">

                        React JS {type}
                    </a>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNav">
                        <ul className="navbar-nav ms-auto">
                            <li className="nav-item">
                                <a className="nav-link active" href="https://sivasoft.in">Home</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="https://sivasoft.in/classroom-training-courses-list.html">Classroom</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link"
                                    href="https://sivasoft.in/online-training-courses-list.html">Online</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link"
                                    href="https://sivasoft.in/sivasoft-batch-timings.html">Timings</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link"
                                    href="https://forms.gle/ygt3scEY32hvnrxz6"
                                    target="_blank" rel="noopener noreferrer">Apply For Course</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link"
                                    href="https://forms.gle/7Ame823xF4VK1Xm87"
                                    target="_blank" rel="noopener noreferrer">Apply For Faculty</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link"
                                    href="https://sivasoft.in/contact.html">Contact</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
            <hr />
            <div className='container'>
                <h6 className="mt-1 mt-2 pt-3">NOTE: Our First Priority is to provide customized Timings
                    &
                    Duration as per Student Requirement</h6>

                <a href="https://sivasoft.in/sivasoft-batch-timings.html">Click here for batch timings</a>
                <div className="row mt-3 pt-3 px-3">
                    <div className="col-md-12">
                        <div className="mt-3">
                            <hr />
                            <div className="button text-center">
                                <a href="https://forms.gle/ygt3scEY32hvnrxz6" target="_blank" rel="noopener noreferrer"
                                    className="main-btn">Apply
                                    For Course</a>
                            </div>
                            <hr />
                            <a
                                href="https://www.sivasoft.in/online-ms-office-training-course.html">🚀 100% Offline / In-Person Classroom and Online Job-Oriented Programs: Master Front-End UI Technologies with React</a>
                            <hr />
                            <p>Group Training Fee: <s style={{color:"red"}}>₹14,000/-</s> ➡️ Offer: <span
                                        style={{color:"green"}}>₹7,000/-</span></p>
                                <p>One-On-One Training Fee: <s style={{color:"green"}}>₹80,000/-</s> ➡️ Offer: <span
                                        style={{color:"green"}}>₹40,000/-</span></p>
                                <p class="mb-3">Duration: 4 Months</p>
                                <div class="row mt-3 mb-3">

                                    <div class="col-md-6">
                                        <p>🎓 Eligibility: Final Year / Any Graduates / Career Gap</p>
                                        <p>👨‍🏫 Trainer: Mr.Siva (15+Yrs of Exp) / Mr.Ravi (12+Yrs of Exp)</p>
                                        <p>🌟 Offer: Valid only for 7 days</p>
                                        <p>⏰ Daily: 2 hrs Live Class with Unlimited Lab</p>
                                        <p>🏢 250+ Companies are Hiring from SivaSoft</p>
                                        <p>🌐 Website: https://www.sivasoft.in</p>
                                        <p>📝 Quizzes, Assignments, Resume Preparation, Mock
                                            Interviews</p>
                                        <p>🔗 LinkedIn Profile Creation, Interview Questions and
                                            System Tasks</p>
                                        <p>💼 100% Placement Support Until You Get a Job</p>
                                        <p>🔔 Pay 10% after placement, based on the package.</p>
                                        <p>💸 Salary Range: 2.5 – 60 LPA</p>

                                    </div>
                                    <div class="col-md-6">

                                        <p>❓ Doubts Clarification: 7:00 AM - 10:00 PM</p>
                                        <p>🎓 Free Course Completion Certificate</p>
                                        <p>📝 Free Internship Certificate</p>
                                        <p>🌐 Real-time Environment Terminology</p>
                                        <p>💡 Tips and Tricks to Survive in Companies</p>

                                        <p>
                                            <strong>📚 Lifetime: Live Classes + Video Access</strong>
                                        </p>
                                        <p>✨ One-on-One Demo Session</p>
                                        <p>Fee: ₹3000 (for Soft Skills, Aptitude, and Reasoning)</p>
                                        <p>🎤 Soft Skills Training (Optional)</p>
                                        <p>🧠 Aptitude (Optional)</p>
                                        <p>🧮 Reasoning (Optional)</p>
                                    </div>
                                </div>
                                <h4 class="mb-2"> Course Curriculum </h4>

                                <ul class="left">
                                    <li>1. Fundamentals of Web Technology</li>
                                    <li>2. HTML</li>
                                    <li>3. XHTML</li>
                                    <li>4. CSS</li>
                                    <li>5. CSS 3,4</li>
                                    <li>6. Basic & Advanced
                                        Javascript</li>
                                    <li>7. Async / Await</li>
                                    <li>8. Ajax</li>
                                    <li>9. JSON</li>
                                    <li>10. HTML5</li>
                                    <li>11. Bootstrap</li>
                                    <li>12. Social Media
                                        Integrations</li>
                                    <li>13. Search Engine Optimization</li>
                                    <li>14. Google Analytics Integration</li>
                                    <li>Project 1: HTML & CSS Project</li>
                                    <li>Project 2: HTML5 & CSS3 Responsive Project with Floats</li>
                                    <li>Project 3: HTML5 & CSS3 Responsive Project with Flexbox</li>
                                    <li>Project 4: HTML5 & CSS3 Responsive Project with Grid</li>
                                    <li>Project 5: JavaScript Project</li>
                                    <li>Project 6: Bootstrap Responsive Project</li>
                                    <li>15. Domain Registration & Web Hosting</li>
                                    <li>16. ECMAScript</li>
                                    <li>17. TypeScript</li>
                                    <li>18. SASS / SCSS</li>
                                    <li>19. React (JavaScript (.js) & JavaScript XML (.jsx))</li>
                                    <li>20. React (TypeScript (.ts) & TypeScript XML (.tsx))</li>
                                    <li>21. Tailwind CSS</li>
                                    <li>22. Material UI</li>
                                    <li>23. Redux</li>
                                    <li>24. Jest (Testing Framework)</li>
                                    <li>25. GraphQL</li>
                                    <li>26. Next.JS</li>
                                    <li>27. Figma</li>
                                    <li>28. Developer Tools and Debugging</li>
                                    <li>29. CRUD (Create, Read, Update, Delete)</li>
                                    <li>30. RESTful APIs to fetch, send, and manipulate data from the server</li>
                                    <li>31. JSON Web Token (JWT)</li>
                                    <li>32. User Authentication and Authorization</li>
                                    <li>33. Bitbucket / GitHub (Version Control with Git)</li>
                                    <li>34. Jira</li>
                                    <li>35. Confluence</li>
                                    <li>36. Firebase / AWS Cloud / Docker / Kubernetes / Jenkins</li>
                                    <li>37. ChatGPT, HackerRank, LeetCode, CodeChef</li>
                                    <li>38. Postman</li>
                                    <li>39. Generative AI</li>
                                    <li>Project 7: React (E-Commerce)</li>
                                    <li>40. Deployment</li>
                                </ul>
                                <hr/>

                                <h6 class="mt-2">1. Fundamentals of Web Technology</h6>
                                <ul class="left">
                                    <li>Network</li>
                                    <li>Internet</li>
                                    <li>Web Browsers</li>
                                    <li>Web Servers</li>
                                    <li>Web</li>
                                    <li>Webpage</li>
                                    <li>Website</li>
                                    <li>Http</li>
                                    <li>Https</li>
                                    <li>Web Development</li>
                                </ul>
                                <h6 class="mt-2">2. HTML</h6>
                                <ul class="left">
                                    <li>Introduction
                                        <ul class="left">
                                            <li>W3C</li>
                                            <li>Editors</li>
                                            <li>Tag
                                                <ul class="left">
                                                    <li>Empty Tag</li>
                                                    <li>Container Tag</li>
                                                </ul>
                                            </li>
                                            <li>Attributes & Parameters</li>
                                            <li>Doctypes: DTD</li>
                                            <li>Structure Of HTML</li>
                                            <li>Basic Tags
                                                <ul class="left">
                                                    <li>html</li>
                                                    <li>head</li>
                                                    <li>title</li>
                                                    <li>body</li>
                                                </ul>
                                            </li>
                                            <li>Tag vs Element</li>
                                            <li>Headings</li>
                                            <li>Formatting Tags</li>
                                            <li>Paragraph tag</li>
                                            <li>Pre tag</li>
                                            <li>abbr tag</li>
                                            <li>List
                                                <ul class="left">
                                                    <li>Ordered List</li>
                                                    <li>Unordered List</li>
                                                    <li>Nested List</li>
                                                    <li>Description List</li>
                                                </ul>
                                            </li>
                                            <li>Iframe</li>
                                            <li>hr</li>
                                            <li>table</li>
                                            <li>JPG / PNG / GIF</li>
                                            <li>Image tag
                                                <ul class="left">
                                                    <li>File Paths</li>
                                                </ul>
                                            </li>
                                            <li>Links
                                                <ul class="left">
                                                    <li>Image Link</li>
                                                    <li>Text Link</li>
                                                    <li>Email Link</li>
                                                </ul>
                                            </li>
                                            <li>Quotation</li>
                                            <li>Citation</li>
                                            <li>Comments</li>
                                            <li>Colors</li>
                                            <li>Computer Code</li>
                                            <li>Entities</li>
                                            <li>Symbols</li>
                                            <li>Emojis</li>
                                            <li>Charset</li>
                                            <li>URL Encode</li>
                                            <li>Meta Tags</li>
                                            <li>Form tags</li>
                                            <li>Style Guide</li>
                                            <li>Deprecated tags
                                                <ul class="left">
                                                    <li>center</li>
                                                    <li>font</li>
                                                    <li>basefont</li>
                                                    <li>s</li>
                                                    <li>strike</li>
                                                    <li>u</li>
                                                    <li>applet</li>
                                                    <li>isindex</li>
                                                    <li>acronym</li>
                                                    <li>frame</li>
                                                    <li>frameset</li>
                                                    <li>noframes</li>
                                                    <li>embed</li>
                                                    <li>marquee</li>
                                                    <li>big</li>
                                                    <li>dir</li>
                                                    <li>spacer</li>
                                                    <li>tt</li>
                                                </ul>
                                            </li>
                                            <li>Deprecated Attributes
                                                <ul class="left">
                                                    <li>align - caption, iframe, img, input, legend, table, hr, div, h1,
                                                        h2, h3, h4, h5, h6, p,
                                                        td, th, and tr.</li>
                                                    <li>hspace - img</li>
                                                    <li>vspace - img</li>
                                                    <li>width - hr, table, td, th, pre, img.</li>
                                                    <li>height - table, td, th, pre, img.</li>
                                                    <li>background - body</li>
                                                    <li>bgcolor - table, tr, td, th and body.</li>
                                                    <li>rules - table</li>
                                                    <li>border - table, img</li>
                                                    <li>cellpadding - table</li>
                                                    <li>cellspacing - table</li>
                                                    <li>size - hr</li>
                                                    <li>type - li, ol and ul</li>
                                                    <li>noshade - hr</li>
                                                    <li>char</li>
                                                    <li>charoff</li>
                                                    <li>color, face - font</li>
                                                    <li>marginwidth, marginheight -
                                                        iframe, body</li>
                                                    <li>nowrap - td,th</li>
                                                    <li>size - font,hr</li>
                                                    <li>start - ol</li>
                                                    <li>valign - td, th, tr</li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                                <h6 class="mt-2">3. XHTML</h6>

                                <ul class="left">
                                    <li>XHTML</li>
                                    <li>Differences between HTML and XHTML</li>
                                </ul>

                                <h6 class="mt-2">4. CSS</h6>
                                <ul class="left">
                                    <li>Introduction</li>
                                    <li>Syntax</li>
                                    <li>Types of StyleSheets
                                        <ul class="left">
                                            <li>Internal StyleSheets</li>
                                            <li>External StyleSheets</li>
                                            <li>Inline StyleSheets</li>
                                            <li>Compound StyleSheet</li>
                                        </ul>
                                    </li>
                                    <li>!important</li>
                                    <li>Types of Selectors
                                        <ul class="left">
                                            <li>Simple
                                                <ul class="left">
                                                    <li>Universal</li>
                                                    <li>Element</li>
                                                    <li>Class</li>
                                                    <li>Element.class</li>
                                                    <li>Id</li>
                                                    <li>Element.id</li>
                                                    <li>Grouping</li>
                                                </ul>
                                            </li>
                                            <li>Combinators
                                                <ul class="left">
                                                    <li>Descendant</li>
                                                    <li>Child</li>
                                                    <li>Adjacent</li>
                                                    <li>General</li>
                                                </ul>
                                            </li>
                                            <li>Pseudo-class(Links)</li>
                                            <li>Pseudo-elements</li>
                                            <li>Attribute</li>
                                        </ul>
                                    </li>
                                    <li>Block & Inline</li>
                                    <li>inline-block</li>
                                    <li>Div's & Span tags</li>
                                    <li>display</li>
                                    <li>Visibility</li>
                                    <li>Units</li>
                                    <li>Box Model
                                        <ul class="left">
                                            <li>Border
                                                <ul class="left">
                                                    <li>border</li>
                                                    <li>border-color</li>
                                                    <li>border-style</li>
                                                    <li>border-width</li>
                                                    <li>border-bottom
                                                        <ul class="left">
                                                            <li>border-bottom-color</li>
                                                            <li>border-bottom-style</li>
                                                            <li>border-bottom-width</li>
                                                        </ul>
                                                    </li>
                                                    <li>border-left
                                                        <ul class="left">
                                                            <li>border-left-color</li>
                                                            <li>border-left-style</li>
                                                            <li>border-left-width</li>
                                                        </ul>
                                                    </li>
                                                    <li>border-right
                                                        <ul class="left">
                                                            <li>border-right-color</li>
                                                            <li>border-right-style</li>
                                                            <li>border-right-width</li>
                                                        </ul>
                                                    </li>
                                                    <li>border-top
                                                        <ul class="left">
                                                            <li>border-top-color</li>
                                                            <li>border-top-style</li>
                                                            <li>border-top-width</li>
                                                        </ul>
                                                    </li>
                                                </ul>
                                            </li>
                                            <li>outline
                                                <ul class="left">
                                                    <li>outline-style</li>
                                                    <li>outline-width</li>
                                                    <li>outline-color</li>
                                                </ul>
                                            </li>
                                            <li>margin
                                                <ul class="left">
                                                    <li>margin-bottom</li>
                                                    <li>margin-left</li>
                                                    <li>margin-right</li>
                                                    <li>margin-top</li>
                                                </ul>
                                            </li>
                                            <li>padding
                                                <ul class="left">
                                                    <li>padding-bottom</li>
                                                    <li>padding-left</li>
                                                    <li>padding-right</li>
                                                    <li>padding-top</li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </li>
                                    <li>Font
                                        <ul class="left">
                                            <li>font-family</li>
                                            <li>color</li>
                                            <li>font-size</li>
                                            <li>Set Font Size With Pixels</li>
                                            <li>Set Font Size With em</li>
                                            <li>font-style</li>
                                            <li>font-variant</li>
                                            <li>font-weight</li>
                                            <li>font</li>
                                        </ul>
                                    </li>



                                    <li>Text
                                        <ul class="left">
                                            <li>Color</li>
                                            <li>letter-spacing</li>
                                            <li>Word-spacing</li>
                                            <li>line-height</li>
                                            <li>text-align</li>
                                            <li>text-align-last</li>
                                            <li>text-decoration
                                                <ul class="left">
                                                    <li>text-decoration-line</li>
                                                    <li>text-decoration-color</li>
                                                    <li>text-decoration-style</li>
                                                    <li>text-decoration-thickness</li>
                                                </ul>
                                            </li>
                                            <li>text-indent</li>
                                            <li>text-transform</li>
                                            <li>vertical-align</li>
                                            <li>White-space</li>
                                            <li>Direction</li>
                                            <li>unicode-bidi</li>
                                        </ul>
                                    </li>
                                    <li>Background
                                        <ul class="left">
                                            <li>background-color</li>
                                            <li>background-image</li>
                                            <li>background-repeat</li>
                                            <li>background-attachment</li>
                                            <li>background-position</li>
                                            <li>background</li>
                                        </ul>
                                    </li>
                                    <li>Image Sprites</li>
                                    <li>Comments</li>
                                    <li>Icons</li>
                                    <li>Images</li>
                                    <li>opacity</li>
                                    <li>FORMS</li>
                                    <li>Links
                                        <ul class="left">
                                            <li>a:link</li>
                                            <li>a:visited</li>
                                            <li>a:hover</li>
                                            <li>a:active</li>
                                        </ul>
                                    </li>
                                    <li>cursor</li>
                                    <li>Lists
                                        <ul class="left">
                                            <li>list-style</li>
                                            <li>list-style-type</li>
                                            <li>list-style-position</li>
                                            <li>list-style-image</li>
                                        </ul>
                                    </li>
                                    <li>Tables
                                        <ul class="left">
                                            <li>border</li>
                                            <li>border-collapse</li>
                                            <li>width</li>
                                            <li>height</li>
                                            <li>text-align</li>
                                            <li>vertical-align</li>
                                            <li>padding</li>
                                        </ul>
                                    </li>
                                    <li>Dimension
                                        <ul class="left">
                                            <li>height</li>
                                            <li>width</li>
                                            <li>max-height</li>
                                            <li>min-height</li>
                                            <li>max-width</li>
                                            <li>min-width</li>
                                        </ul>
                                    </li>
                                    <li>Positioning
                                        <ul class="left">
                                            <li>bottom</li>
                                            <li>clip</li>
                                            <li>left</li>
                                            <li>overflow</li>
                                            <li>absolute</li>
                                            <li>fixed</li>
                                            <li>relative</li>
                                            <li>static</li>
                                            <li>right</li>
                                            <li>top</li>
                                            <li>z-index</li>
                                            <li>sticky</li>
                                        </ul>
                                    </li>
                                    <li>float & clear</li>
                                    <li>Counters</li>
                                    <li>Specificity</li>
                                    <li>Math functions</li>
                                    <li>Navigation (Menu) Bar</li>
                                    <li>Dropdown Navigation Bar</li>
                                    <li>Project 1: HTML & CSS Project</li>
                                </ul>


                                <h6 class="mt-2">5. CSS 3,4</h6>

                                <ul class="left">
                                    <li>Introduction to CSS3</li>
                                    <li>What's New in CSS3 and CSS4</li>
                                    <li>border-image</li>
                                    <li>box-shadow</li>
                                    <li>text-shadow</li>
                                    <li>border-radius</li>
                                    <li>Image Reflection</li>
                                    <li>background
                                        <ul class="left">
                                            <li>background-clip</li>
                                            <li>background-origin</li>
                                            <li>background-size</li>
                                            <li>Multiple Background Images</li>
                                        </ul>
                                    </li>
                                    <li>Color Keywords</li>
                                    <li>Gradients
                                        <ul class="left">
                                            <li>Linear Gradients</li>
                                            <li>Radial Gradients</li>
                                            <li>Conic Gradients</li>
                                        </ul>
                                    </li>
                                    <li>text effects
                                        <ul class="left">
                                            <li>word-wrap</li>
                                            <li>text-overflow</li>
                                            <li>word-break</li>
                                            <li>writing-mode</li>
                                        </ul>
                                    </li>
                                    <li>Web fonts</li>
                                    <li>Google fonts</li>
                                    <li>2D Transforms</li>
                                    <li>3D Transforms</li>
                                    <li>RGBA</li>
                                    <li>Box Sizing</li>
                                    <li>Transitions</li>
                                    <li>Animations</li>
                                    <li>Style Images</li>
                                    <li>object-fit</li>
                                    <li>object-position</li>
                                    <li>Masking</li>
                                    <li>Multiple Columns</li>
                                    <li>User Interface</li>
                                    <li>Variables</li>
                                    <li>Box-sizing</li>
                                    <li>Flex</li>
                                    <li>Media Queries</li>
                                    <li>Orientation</li>
                                    <li>favicon</li>
                                    <li>Grid</li>
                                    <li>Project 2: HTML5 & CSS3 Responsive Project with Floats</li>
                                    <li>Project 3: HTML5 & CSS3 Responsive Project with Flexbox</li>
                                    <li>Project 4: HTML5 & CSS3 Responsive Project with Grid</li>
                                    <li>Content will be updated as per latest updates</li>
                                </ul>



                                <h6 class="mt-2">6. Basic & Advanced
                                    Javascript</h6>

                                <ul class="left">
                                    <li>Introduction to JavaScript</li>
                                    <li>Syntax</li>
                                    <li>Statements</li>
                                    <li>Types of Scripts
                                        <ul class="left">
                                            <li>Internal</li>
                                            <li>External</li>
                                            <li>Inline</li>
                                        </ul>
                                    </li>
                                    <li>Write / Writeln</li>
                                    <li>innerHTML</li>
                                    <li>innerText</li>
                                    <li>textContent</li>
                                    <li>console</li>
                                    <li>Print</li>
                                    <li>Popup Boxes
                                        <ul class="left">
                                            <li>alert</li>
                                            <li>confirm</li>
                                            <li>prompt</li>
                                        </ul>
                                    </li>
                                    <li>Naming Conventions
                                        <ul class="left">
                                            <li>Lower Camel Case</li>
                                            <li>Upper Camel Case</li>
                                            <li>Snake Case</li>
                                            <li>Kebab Case</li>
                                            <li>Screaming Snake Case</li>
                                            <li>Train Case</li>
                                            <li>Flat Case</li>
                                            <li>Upper Flat Case</li>
                                            <li>Title Case</li>
                                            <li>Sentence Case</li>
                                            <li>Toggle Case</li>
                                        </ul>
                                    </li>
                                    <li>Comments</li>
                                    <li>Variables</li>
                                    <li>Hoisting</li>
                                    <li>Operators
                                        <ul class="left">
                                            <li>Arithmetic</li>
                                            <li>Assignment</li>
                                            <li>String</li>
                                            <li>Type</li>
                                            <li>Comparison</li>
                                            <li>Logical</li>
                                        </ul>
                                    </li>
                                    <li>Conditional Statements
                                        <ul class="left">
                                            <li>if</li>
                                            <li>if...else</li>
                                            <li>if...else if...else</li>
                                            <li>Switch</li>
                                        </ul>
                                    </li>
                                    <li>Loops
                                        <ul class="left">
                                            <li>while</li>
                                            <li>do...while</li>
                                            <li>for</li>
                                            <li>for...in</li>
                                            <li>for...of (ES6)</li>
                                            <li>Break</li>
                                            <li>Continue</li>
                                        </ul>
                                    </li>
                                    <li>Functions
                                        <ul class="left">
                                            <li>User-defined</li>
                                            <li>Syntax</li>
                                            <li>Parameters and Arguments</li>
                                            <li>Returning Values</li>
                                            <li>Self-Invoked</li>
                                        </ul>
                                    </li>

                                    <li>Functions
                                        <ul class="left">
                                            <li>Declaration</li>
                                            <li>Expression</li>
                                            <li>Self-Invoked</li>
                                            <li>this
                                                <ul class="left">
                                                    <li>call</li>
                                                    <li>apply</li>
                                                    <li>bind</li>
                                                </ul>
                                            </li>
                                            <li>Closures</li>
                                            <li>Local and Global Variables</li>
                                            <li>Recursive Functions</li>
                                            <li>Built-in Functions
                                                <ul class="left">
                                                    <li>Global</li>
                                                    <li>Number</li>
                                                    <li>String</li>
                                                    <li>Array</li>
                                                    <li>Math</li>
                                                    <li>Date</li>
                                                    <li>JSON</li>
                                                    <li>Utility</li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </li>
                                    <li>Events
                                        <ul class="left">
                                            <li>Introduction</li>
                                            <li>Mouse Events</li>
                                            <li>Keyboard Events</li>
                                            <li>Form Events</li>
                                            <li>Document Events</li>
                                            <li>Window Events</li>
                                            <li>Event Bubbling</li>
                                        </ul>
                                    </li>
                                    <li>Page Redirection</li>
                                    <li>Timing Events
                                        <ul class="left">
                                            <li>setTimeout</li>
                                            <li>setInterval</li>
                                        </ul>
                                    </li>
                                    <li>Built-in Objects
                                        <ul class="left">
                                            <li>Introduction</li>
                                            <li>Arrays</li>
                                            <li>Array Properties and Methods</li>
                                            <li>Number</li>
                                            <li>Strings</li>
                                            <li>Math</li>
                                            <li>Date</li>
                                            <li>Boolean</li>
                                            <li>Regexp</li>
                                            <li>Exception Handling (Errors)
                                                <ul class="left">
                                                    <li>try</li>
                                                    <li>catch</li>
                                                    <li>throw</li>
                                                    <li>finally</li>
                                                    <li>Fetch</li>
                                                    <li>Error Object Properties</li>
                                                    <li>JSON (JavaScript Object Notation)</li>
                                                </ul>
                                            </li>
                                            <li>Object
                                                <ul class="left">
                                                    <li>Creating a JavaScript Object</li>
                                                    <li>JavaScript Keyword new</li>
                                                    <li>Properties</li>
                                                    <li>Adding Methods to an Object</li>
                                                    <li>Nesting</li>
                                                    <li>Display</li>
                                                    <li>Using an Object Constructor</li>
                                                    <li>Prototypes</li>
                                                    <li>Getter / Setter</li>
                                                    <li>Protection</li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </li>
                                    <li>Debugging
                                        <ul class="left">
                                            <li>console</li>
                                            <li>developer tools</li>
                                            <li>debugger</li>
                                            <li>try…catch</li>
                                            <li>breakpoints</li>
                                            <li>Network</li>
                                        </ul>
                                    </li>
                                    <li>DOM (Document Object Model)
                                        <ul class="left">
                                            <li>Introduction</li>
                                            <li>Methods</li>
                                            <li>Document</li>
                                            <li>Elements</li>
                                            <li>HTML</li>
                                            <li>Forms</li>
                                            <li>CSS</li>
                                            <li>Animations</li>
                                            <li>Events</li>
                                            <li>EventListener</li>
                                            <li>Navigation</li>
                                            <li>Nodes</li>
                                            <li>Collections</li>
                                            <li>Node Lists</li>
                                        </ul>
                                    </li>
                                    <li>Browser BOM
                                        <ul class="left">
                                            <li>Window</li>
                                            <li>Screen</li>
                                            <li>Location</li>
                                            <li>History</li>
                                            <li>Navigator</li>
                                            <li>Popup</li>
                                            <li>Timing</li>
                                            <li>Cookies</li>
                                        </ul>
                                    </li>
                                    <li>Execution Context and Call Stack</li>
                                    <li>Form Validations
                                        <ul class="left">
                                            <li>Basics of Form Validation</li>
                                            <li>Validating Radio Buttons</li>
                                            <li>Validating Checkboxes</li>
                                            <li>Validating Select Menus</li>
                                            <li>Validating Text & Textareas</li>
                                            <li>Validating Through Regular Expressions: Alphabetical, Alphanumeric,
                                                Email, Date, Image,
                                                Password, Phone, Zip Code, and so on</li>
                                        </ul>
                                    </li>
                                    <li>Project 5: JavaScript Project</li>
                                </ul>
                                <h6 class="mt-2">7. Async / Await</h6>
                                <ul class="left">
                                    <li>Callbacks</li>
                                    <li>Asynchronous</li>
                                    <li>Promises</li>
                                    <li>Async / Await</li>
                                </ul>
                                <h6 class="mt-2">8. Ajax</h6>
                                <ul class="left">
                                    <li>Introduction</li>
                                    <li>XMLHttpRequest</li>
                                    <li>Request</li>
                                    <li>Response</li>
                                    <li>JSON</li>
                                    <li>REST API</li>
                                    <li>GET</li>
                                    <li>POST</li>
                                    <li>PUT</li>
                                    <li>DELETE</li>
                                </ul>
                                <h6 class="mt-2">9. JSON</h6>
                                <ul class="left">
                                    <li>Introduction</li>
                                    <li>Syntax</li>
                                    <li>JSON vs XML</li>
                                    <li>Datatypes</li>
                                    <li>parse</li>
                                    <li>stringify</li>
                                    <li>Object</li>
                                    <li>Arrays</li>
                                    <li>Server</li>
                                    <li>Html</li>
                                    <li>JSON vs JSONP</li>
                                </ul>



                                <h6 class="mt-2">10. HTML 5</h6>


                                <ul class="left">
                                    <li>What is HTML5</li>
                                    <li>Overview of HTML5</li>
                                    <li>Browser Support</li>
                                    <li>W3C and Web Hypertext Application Technology Working Group (WHATWG)
                                        Specifications</li>
                                    <li>Overview of Updated New Features</li>
                                    <li>HTML5 Syntax
                                        <ul class="left">
                                            <li>The DOCTYPE</li>
                                            <li>Character Encoding</li>
                                            <li>The &lt;script&gt; tag</li>
                                            <li>The &lt;link&gt; tag</li>
                                            <li>HTML5 Document</li>
                                        </ul>
                                    </li>
                                    <li>Forms
                                        <ul class="left">
                                            <li>email</li>
                                            <li>url</li>
                                            <li>tel</li>
                                            <li>number</li>
                                            <li>range</li>
                                            <li>date</li>
                                            <li>time</li>
                                            <li>datetime</li>
                                            <li>datetime-local</li>
                                            <li>month</li>
                                            <li>week</li>
                                            <li>color</li>
                                        </ul>
                                    </li>
                                    <li>Form Elements
                                        <ul class="left">
                                            <li>datalist</li>
                                            <li>output</li>
                                            <li>progress</li>
                                            <li>meter</li>
                                            <li>keygen</li>
                                            <li>fieldset</li>
                                        </ul>
                                    </li>
                                    <li>New Attributes for &lt;form&gt;
                                        <ul class="left">
                                            <li>autocomplete</li>
                                            <li>novalidate</li>
                                            <li>target</li>
                                            <li>formaction</li>
                                            <li>formenctype</li>
                                            <li>formmethod</li>
                                            <li>formnovalidate</li>
                                            <li>formtarget</li>
                                            <li>accept-charset</li>
                                        </ul>
                                    </li>
                                    <li>New Attributes for &lt;input&gt;
                                        <ul class="left">
                                            <li>autocomplete</li>
                                            <li>autofocus</li>
                                            <li>form</li>
                                            <li>list</li>
                                            <li>min and max</li>
                                            <li>multiple</li>
                                            <li>pattern (regexp)</li>
                                            <li>placeholder</li>
                                            <li>readonly</li>
                                            <li>required</li>
                                            <li>step</li>
                                            <li>size</li>
                                            <li>maxLength</li>
                                            <li>inputmode</li>
                                            <li>formaction</li>
                                            <li>formenctype</li>
                                            <li>formmethod</li>
                                            <li>formnovalidate</li>
                                            <li>formtarget</li>
                                        </ul>
                                    </li>
                                    <li>Multimedia Elements
                                        <ul class="left">
                                            <li>Media</li>
                                            <li>audio</li>
                                            <li>video</li>
                                            <li>source</li>
                                            <li>track</li>
                                            <li>embed</li>
                                            <li>canvas</li>
                                            <li>svg</li>
                                            <li>plugins</li>
                                            <li>youtube</li>
                                        </ul>
                                    </li>
                                    <li>Types of Elements
                                        <ul class="left">
                                            <li>Semantic Elements</li>
                                            <li>Structural Elements</li>
                                        </ul>
                                    </li>
                                    <li>HTML5 New Elements
                                        <ul class="left">
                                            <li>article</li>
                                            <li>section</li>
                                            <li>header</li>
                                            <li>footer</li>
                                            <li>nav</li>
                                            <li>aside</li>
                                            <li>main</li>
                                            <li>figcaption</li>
                                            <li>figure</li>
                                            <li>time</li>
                                            <li>mark</li>
                                            <li>details</li>
                                            <li>summary</li>
                                            <li>progress</li>
                                            <li>output</li>
                                            <li>address</li>
                                            <li>dialog</li>
                                            <li>template</li>
                                            <li>slot</li>
                                            <li>wbr</li>
                                            <li>hgroup</li>
                                        </ul>
                                    </li>
                                    <li>Migration from HTML4 to HTML5
                                        <ul class="left">
                                            <li>HTML5 Browser Support</li>
                                            <li>HTML5 Elements as Block Elements</li>
                                            <li>Adding New Elements to HTML</li>
                                            <li>Problem with Internet Explorer</li>
                                            <li>Complete Shiv Solution</li>
                                            <li>HTML5 Skeleton</li>
                                        </ul>
                                    </li>
                                    <li>HTML5 Deprecated Tags
                                        <ul class="left">
                                            <li>center</li>
                                            <li>font</li>
                                            <li>basefont</li>
                                            <li>s</li>
                                            <li>strike</li>
                                            <li>u</li>
                                            <li>applet</li>
                                            <li>isindex</li>
                                            <li>acronym</li>
                                            <li>frame</li>
                                            <li>frameset</li>
                                            <li>noframes</li>
                                            <li>embed</li>
                                            <li>marquee</li>
                                            <li>big</li>
                                            <li>dir</li>
                                            <li>spacer</li>
                                            <li>tt</li>
                                        </ul>
                                    </li>
                                    <li>HTML5 Deprecated Attributes
                                        <ul class="left">
                                            <li>align - caption, iframe, img, input, legend, table, hr, div, h1, h2, h3,
                                                h4, h5, h6, p, td,
                                                th, and tr.</li>
                                            <li>hspace - img</li>
                                            <li>vspace - img</li>
                                            <li>width - hr, table, td, th, pre, img.</li>
                                            <li>height - table, td, th, pre, img.</li>
                                            <li>background - body</li>
                                            <li>bgcolor - table, tr, td, th and body.</li>
                                            <li>rules - table</li>
                                            <li>border - table, img</li>
                                            <li>cellpadding - table</li>
                                            <li>cellspacing - table</li>
                                            <li>size - hr</li>
                                            <li>type - li, ol and ul</li>
                                            <li>noshade - hr</li>
                                            <li>char</li>
                                            <li>charoff</li>
                                            <li>color, face - font</li>
                                            <li>marginwidth, marginheight - iframe, body</li>
                                            <li>nowrap - td, th</li>
                                            <li>size - font, hr</li>
                                            <li>start - ol</li>
                                            <li>valign - td, th, tr</li>
                                        </ul>
                                    </li>
                                    <li>Web Storage
                                        <ul class="left">
                                            <li>Local Storage</li>
                                            <li>Session Storage</li>
                                        </ul>
                                    </li>
                                    <li>Web SQL</li>
                                    <li>Web Workers</li>
                                    <li>MathML</li>
                                    <li>Drag and Drop API</li>
                                    <li>Canvas Overview
                                        <ul class="left">
                                            <li>Lines</li>
                                            <li>Curves</li>
                                            <li>font</li>
                                            <li>Image</li>
                                            <li>Paths</li>
                                            <li>Shapes</li>
                                            <li>Color, Gradients</li>
                                        </ul>
                                    </li>
                                    <li>SVG
                                        <ul class="left">
                                            <li>What is SVG?</li>
                                            <li>SVG Advantages</li>
                                            <li>Differences Between SVG and Canvas</li>
                                            <li>Rectangle</li>
                                            <li>Circle</li>
                                            <li>Ellipse</li>
                                            <li>Line</li>
                                            <li>Polygon</li>
                                        </ul>
                                    </li>
                                </ul>



                                <h6 class="mt-2">11. Bootstrap</h6>


                                <ul class="left">
                                    <li>What is Bootstrap?</li>
                                    <li>Bootstrap History</li>
                                    <li>Why Use Bootstrap?</li>
                                    <li>What Does Bootstrap Include?</li>
                                    <li>HTML File</li>
                                    <li>Adding Bootstrap to Your Web Pages</li>
                                    <li>Downloading Bootstrap</li>
                                    <li>Bootstrap CDN</li>
                                    <li>Put Your HTML Elements Inside Containers</li>
                                    <li>Bootstrap Browser/Device Support</li>
                                    <li>Layout
                                        <ul class="left">
                                            <li>Breakpoints</li>
                                            <li>Containers</li>
                                            <li>Grid</li>
                                            <li>Columns</li>
                                            <li>Gutters</li>
                                            <li>Utilities</li>
                                            <li>Z-index</li>
                                            <li>CSS Grid</li>
                                        </ul>
                                    </li>
                                    <li>Content
                                        <ul class="left">
                                            <li>Reboot</li>
                                            <li>Typography</li>
                                            <li>Images</li>
                                            <li>Tables</li>
                                            <li>Figures</li>
                                        </ul>
                                    </li>
                                    <li>Forms
                                        <ul class="left">
                                            <li>Overview</li>
                                            <li>Form control</li>
                                            <li>Select</li>
                                            <li>Checks & radios</li>
                                            <li>Range</li>
                                            <li>Input group</li>
                                            <li>Floating labels</li>
                                            <li>Layout</li>
                                            <li>Validation</li>
                                        </ul>
                                    </li>
                                    <li>Components
                                        <ul class="left">
                                            <li>Accordion</li>
                                            <li>Alerts</li>
                                            <li>Badge</li>
                                            <li>Breadcrumb</li>
                                            <li>Buttons</li>
                                            <li>Button group</li>
                                            <li>Card</li>
                                            <li>Carousel</li>
                                            <li>Close button</li>
                                            <li>Collapse</li>
                                            <li>Dropdowns</li>
                                            <li>List group</li>
                                            <li>Modal</li>
                                            <li>Navbar</li>
                                            <li>Navs & tabs</li>
                                            <li>Offcanvas</li>
                                            <li>Pagination</li>
                                            <li>Placeholders</li>
                                            <li>Popovers</li>
                                            <li>Progress</li>
                                            <li>Scrollspy</li>
                                            <li>Spinners</li>
                                            <li>Toasts</li>
                                            <li>Tooltips</li>
                                        </ul>
                                    </li>
                                    <li>Helpers
                                        <ul class="left">
                                            <li>Clearfix</li>
                                            <li>Color & background</li>
                                            <li>Colored links</li>
                                            <li>Focus ring</li>
                                            <li>Icon link</li>
                                            <li>Position</li>
                                            <li>Ratio</li>
                                            <li>Stacks</li>
                                            <li>Stretched link</li>
                                            <li>Text truncation</li>
                                            <li>Vertical rule</li>
                                            <li>Visually hidden</li>
                                        </ul>
                                    </li>
                                    <li>Utilities
                                        <ul class="left">
                                            <li>API</li>
                                            <li>Background</li>
                                            <li>Borders</li>
                                            <li>Colors</li>
                                            <li>Display</li>
                                            <li>Flex</li>
                                            <li>Float</li>
                                            <li>Interactions</li>
                                            <li>Link</li>
                                            <li>Object fit</li>
                                            <li>Opacity</li>
                                            <li>Overflow</li>
                                            <li>Position</li>
                                            <li>Shadows</li>
                                            <li>Sizing</li>
                                            <li>Spacing</li>
                                            <li>Text</li>
                                            <li>Vertical align</li>
                                            <li>Visibility</li>
                                            <li>Z-index</li>
                                        </ul>
                                    </li>
                                    <li>Extend
                                        <ul class="left">
                                            <li>Approach</li>
                                            <li>Icons</li>
                                        </ul>
                                    </li>
                                    <li>Project 6: Bootstrap Responsive Project</li>
                                </ul>
                                <h6 class="mt-2">12. Social Media Integrations</h6>
                                <h6 class="mt-2">13. Search Engine Optimization</h6>
                                <h6 class="mt-2">14. Google Analytics Integration</h6>
                                <h6 class="mt-2">15. Domain Registration & Web Hosting</h6>
                                <h6 class="mt-2">16. ECMAScript:</h6>
                                <ul class="left">
                                    <li>Introduction</li>
                                    <li>Environment</li>
                                    <li>Syntax</li>
                                    <li>Console</li>
                                    <li>Scripting language vs Programming language
                                        <ul class="left">
                                            <li>Execution process</li>
                                            <li>No Intermediate Output</li>
                                            <li>Feedback and Debugging</li>
                                            <li>Usage</li>
                                        </ul>
                                    </li>
                                    <li>Variables
                                        <ul class="left">
                                            <li>Automatically</li>
                                            <li>Using var (js)</li>
                                            <li>using let (es6)</li>
                                            <li>using const (es6)</li>
                                            <li>Differences between var, let and const</li>
                                            <li>Hoisting</li>
                                        </ul>
                                    </li>
                                    <li>Operators
                                        <ul class="left">
                                            <li>Arithmetic</li>
                                            <li>Assignment</li>
                                            <li>String</li>
                                            <li>Type</li>
                                            <li>Comparison</li>
                                            <li>Logical</li>
                                        </ul>
                                    </li>
                                    <li>Decision Making (Conditions)
                                        <ul class="left">
                                            <li>If statement</li>
                                            <li>? ternary operator</li>
                                            <li>If..else statement</li>
                                            <li>If..else if..else statement</li>
                                            <li>switch statement</li>
                                        </ul>
                                    </li>
                                    <li>Loops
                                        <ul class="left">
                                            <li>for</li>
                                            <li>While</li>
                                            <li>do..While</li>
                                            <li>for/in</li>
                                            <li>for/of</li>
                                            <li>break</li>
                                            <li>continue</li>
                                        </ul>
                                    </li>
                                    <li>Functions
                                        <ul class="left">
                                            <li>Anonymous</li>
                                            <li>Shadowing vs Closures</li>
                                            <li>Arrow functions
                                                <ul class="left">
                                                    <li>Parameters</li>
                                                    <li>Implicit return</li>
                                                    <li>this</li>
                                                    <li>No arguments</li>
                                                    <li>No new</li>
                                                    <li>No prototype property</li>
                                                    <li>Returning Object Literal</li>
                                                    <li>Array methods</li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </li>
                                    <li>Primitive Data Types
                                        <ul class="left">
                                            <li>Number</li>
                                            <li>String</li>
                                            <li>Boolean</li>
                                            <li>Null</li>
                                            <li>Undefined</li>
                                            <li>Symbol</li>
                                            <li>BigInt</li>
                                        </ul>
                                    </li>
                                    <li>Literals
                                        <ul class="left">
                                            <li>Numeric</li>
                                            <li>Integers</li>
                                            <li>Floating-Points</li>
                                            <li>String</li>
                                            <li>Boolean</li>
                                            <li>Objects</li>
                                            <li>Array</li>
                                            <li>Template</li>
                                            <li>Regexp or Pattern</li>
                                            <li>Null</li>
                                            <li>Undefined</li>
                                        </ul>
                                    </li>
                                    <li>Rest vs Spread Operator</li>
                                    <li>Destructuring</li>
                                    <li>Modules</li>
                                    <li>Iterator
                                        <ul class="left">
                                            <li>String</li>
                                            <li>Array</li>
                                            <li>Map</li>
                                            <li>Set</li>
                                        </ul>
                                    </li>
                                    <li>Collections
                                        <ul class="left">
                                            <li>Map</li>
                                            <li>Set</li>
                                            <li>WeakMap</li>
                                            <li>WeakSet</li>
                                        </ul>
                                    </li>
                                    <li>Generators</li>
                                    <li>Reflect API</li>
                                    <li>Proxy API</li>
                                    <li>Classes (OOPS: Object-Oriented Programming System)
                                        <ul class="left">
                                            <li>Class Declaration</li>
                                            <li>Class Expression</li>
                                            <li>Constructor</li>
                                            <li>Class Methods</li>
                                            <li>Static Methods</li>
                                            <li>Setters and Getters</li>
                                            <li>instanceof operator</li>
                                            <li>Class Inheritance</li>
                                            <li>Super Keyword</li>
                                            <li>Method Overriding</li>
                                            <li>Private Fields and Methods</li>
                                            <li>Class Fields</li>
                                            <li>Abstract Class</li>
                                            <li>Mixins</li>
                                            <li>Sub-Classing Built-in Objects</li>
                                            <li>Encapsulation</li>
                                            <li>Polymorphism</li>
                                            <li>Mutable Objects</li>
                                        </ul>
                                    </li>
                                    <li>Versions and New Features
                                        <ul class="left">
                                            <li>EcmaScript 1997 : ECMAScript 1(ES1) was standardized by Ecma
                                                International, based on JavaScript 1.1, to create a formal, consistent
                                                specification for the language.</li>
                                            <li>ES2 : 1998</li>
                                            <li>ES3 : 1999</li>
                                            <li>ES4 : Development abandoned</li>
                                            <li>ES5 : 2009 </li>
                                            <li>ES5.1 : 2011</li>
                                            <li>ES6 : 2015</li>
                                            <li>ES7 : 2016</li>
                                            <li>ES8 : 2017</li>
                                            <li>ES9 : 2018</li>
                                            <li>ES10 : 2019</li>
                                            <li>ES11 : 2020</li>
                                            <li>ES12 : 2021</li>
                                            <li>ES13 : 2022</li>
                                            <li>ES14 : 2023 </li>
                                            <li>ES15 : 2024</li>
                                        </ul>
                                    </li>
                                    <li><b>Content will be updated as per latest updates.</b></li>
                                </ul>



                                <h6 class="mt-2">17.
                                    TypeScript:</h6>


                                <ul class="left">
                                    <li>Home</li>
                                    <li>Overview</li>
                                    <li>Environment Setup</li>
                                    <li>Basic Syntax</li>
                                    <li>Types</li>
                                    <li>Variables</li>
                                    <li>Operators</li>
                                    <li>Decision Making</li>
                                    <li>Loops</li>
                                    <li>Functions</li>
                                    <li>Numbers</li>
                                    <li>Strings</li>
                                    <li>Arrays</li>
                                    <li>Tuples</li>
                                    <li>Union</li>
                                    <li>Interfaces</li>
                                    <li>Classes</li>
                                    <li>Objects</li>
                                    <li>Namespaces</li>
                                    <li>Modules</li>
                                </ul>

                                <h5 class="mt-2">18. SASS,
                                    SCSS:</h5>


                                <ul class="left">
                                    <li>Variables</li>
                                    <li>Nesting</li>
                                    <li>Partials</li>
                                    <li>Modules</li>
                                    <li>Mixins</li>
                                    <li>Extend/Inheritance</li>
                                    <li>Operators</li>
                                </ul>




                                <h6 class="mt-2">19. React (JavaScript (.js) & JavaScript XML (.jsx))</h6>


                                <ul class="left">
                                    <li>What is React?</li>
                                    <li>Introduction</li>
                                    <li>Library</li>
                                    <li>Collection of functions</li>
                                    <li>Difference between Functions and Utilities</li>
                                    <li>Classes</li>
                                    <li>What is Single-Page Application (SPA)?
                                        <ul class="left">
                                            <li>How SPAs Work
                                                <ul class="left">
                                                    <li>Initial Load</li>
                                                    <li>Dynamic Updates</li>
                                                </ul>
                                            </li>
                                            <li>Benefits of SPAs</li>
                                        </ul>
                                    </li>
                                    <li>UI Components that manage their own state</li>
                                    <li>State</li>
                                    <li>Components Composed into Complex UIs</li>
                                    <li>Why React?
                                        <ul class="left">
                                            <li>Declarative</li>
                                            <li>Component-based</li>
                                        </ul>
                                    </li>
                                    <li>React History</li>
                                    <li>HTML DOM Vs JavaScript DOM</li>
                                    <li>Real (HTML / JS) DOM
                                        <ul class="left">
                                            <li>reflow</li>
                                            <li>repaint</li>
                                        </ul>
                                    </li>
                                    <li>Virtual DOM</li>
                                    <li>Difference between Real DOM and Virtual DOM
                                        <ul class="left">
                                            <li>Update</li>
                                            <li>Efficiency</li>
                                            <li>Performance Impact</li>
                                            <li>Re-rendering</li>
                                            <li>use Case</li>
                                        </ul>
                                    </li>
                                    <li>npx and npm ecosystem</li>
                                    <li>Creating React Project
                                        <ul class="left">
                                            <li>
                                            <li>Setting up a React
                                                development environment
                                                (Node.js, npm, or Yarn)</li>
                                    </li>
                                    <li>Project structure</li>
                                    <li>Flow of execution</li>
                                </ul>
                                </li>
                                <li>JSX
                                    <ul class="left">
                                        <li>Expressions in JSX</li>
                                        <li>Inserting a large block of html</li>
                                        <li>One Top Level Element</li>
                                        <li>React: XHTML vs Angular: XHTML</li>
                                        <li>CSS className vs class</li>
                                        <li>Conditions</li>
                                        <li>ternary operator (?)</li>
                                        <li>Rendering a List: map()</li>
                                        <li>Rendering Multiple Components</li>
                                        <li>Handling Events in JSX</li>
                                        <li>Inline Styles in JSX</li>
                                        <li>Fragments to Avoid extra wrapping element</li>
                                    </ul>
                                </li>
                                <li>Components
                                    <ul class="left">
                                        <li>Functional component</li>
                                        <li>Class Component</li>
                                        <li>Components in Components</li>
                                        <li>Components in Files</li>
                                    </ul>
                                </li>
                                <li>Props
                                    <ul class="left">
                                        <li>Pass Data</li>
                                        <li>Variables</li>
                                        <li>Object</li>
                                        <li>Mutliple props</li>
                                        <li>Default Props</li>
                                    </ul>
                                </li>
                                <li>Events
                                    <ul class="left">
                                        <li>Passing Arguments</li>
                                        <li>Event Object</li>
                                    </ul>
                                </li>

                                <li>
                                    <strong>Component State
                                        Management</strong>
                                    <ul>
                                        <li>Using the useState hook for
                                            local state management</li>
                                        <li>Managing multiple state
                                            variables</li>
                                        <li>State updates and functional
                                            updates</li>
                                        <li>Controlled components for
                                            form state management</li>
                                    </ul>
                                </li>

                                <li>
                                    <strong>Component Side Effects and
                                        Lifecycle</strong>
                                    <ul>
                                        <li>Managing side effects with
                                            the useEffect hook</li>
                                        <li>Cleaning up with useEffect
                                            and useEffect dependencies
                                        </li>
                                        <li>Custom hooks for reusable
                                            side effect logic</li>
                                        <li>Using useLayoutEffect for
                                            synchronous effects</li>
                                    </ul>
                                </li>

                                <li>
                                    <strong>Routing with React
                                        Router</strong>
                                    <ul>
                                        <li>Setting up React Router</li>
                                        <li>Creating routes with
                                            functional components</li>
                                        <li>Route parameters and
                                            navigation in functional
                                            components</li>
                                    </ul>
                                </li>

                                <li>
                                    <strong>Context and Global
                                        State</strong>
                                    <ul>
                                        <li>Managing global state with
                                            the React Context API</li>
                                        <li>Creating and consuming
                                            context with hooks</li>
                                        <li>Context with multiple values
                                        </li>
                                    </ul>
                                </li>

                                <li>
                                    <strong>Refs and DOM
                                        Manipulation</strong>
                                    <ul>
                                        <li>Working with refs in
                                            functional components</li>
                                        <li>Refs for accessing DOM
                                            elements</li>
                                        <li>Forwarding refs</li>
                                    </ul>
                                </li>

                                <li>
                                    <strong>HTTP Requests and Data
                                        Fetching</strong>
                                    <ul>
                                        <li>Making HTTP requests with
                                            fetch or Axios in functional
                                            components</li>
                                        <li>Fetching data from an API
                                        </li>
                                        <li>Asynchronous operations with
                                            async/await</li>
                                    </ul>
                                </li>

                                <li>
                                    <strong>Styling in React</strong>
                                    <ul>
                                        <li>CSS-in-JS libraries for
                                            styling functional
                                            components (e.g.,
                                            styled-components)</li>
                                        <li>CSS modules for scoped
                                            styling</li>
                                        <li>Inline styles in functional
                                            components</li>
                                    </ul>
                                </li>

                                <li>
                                    <strong>Testing Functional
                                        Components</strong>
                                    <ul>
                                        <li>Unit testing functional
                                            components with Jest</li>
                                        <li>Testing React components
                                            with React Testing Library
                                        </li>
                                        <li>Mocking dependencies for
                                            isolated testing</li>
                                    </ul>
                                </li>

                                <li>
                                    <strong>Performance
                                        Optimization</strong>
                                    <ul>
                                        <li>Using the React.memo hook
                                            for functional components
                                        </li>
                                        <li>Memoization techniques with
                                            hooks</li>
                                        <li>Code splitting and lazy
                                            loading functional
                                            components</li>
                                    </ul>
                                </li>
                                <li>
                                    <strong>CRUD Operations with
                                        React</strong>
                                    <ul>
                                        <li>Creating a CRUD application
                                        </li>
                                        <li>Implementing Create, Read,
                                            Update, Delete operations
                                        </li>
                                        <li>Managing component state for
                                            data</li>
                                        <li>Displaying data in tables
                                            and lists</li>
                                    </ul>
                                </li>

                                <li>
                                    <strong>User Authentication</strong>
                                    <ul>
                                        <li>Implementing user
                                            registration</li>
                                        <li>Storing user credentials
                                            securely</li>
                                        <li>User login and session
                                            management</li>
                                    </ul>
                                </li>

                                <li>
                                    <strong>Authorization and Protected
                                        Routes</strong>
                                    <ul>
                                        <li>Defining user roles and
                                            permissions</li>
                                        <li>Authorizing access to
                                            certain routes and
                                            components</li>
                                        <li>Creating protected routes
                                            with route guards</li>
                                    </ul>
                                </li>

                                <li>
                                    <strong>User Profile and
                                        Authentication State</strong>
                                    <ul>
                                        <li>Displaying user information
                                            in the UI</li>
                                        <li>Managing authentication
                                            state in Redux</li>
                                        <li>Updating user profiles and
                                            passwords</li>
                                    </ul>
                                </li>

                                <li>
                                    <strong>Logout
                                        Functionality</strong>
                                    <ul>
                                        <li>Implementing a secure logout
                                            process</li>
                                        <li>Clearing authentication
                                            tokens and user sessions
                                        </li>
                                        <li>Redirecting users after
                                            logout</li>
                                    </ul>
                                </li>

                                <li>
                                    <strong>Role-Based
                                        Authorization</strong>
                                    <ul>
                                        <li>Assigning roles to users
                                        </li>
                                        <li>Configuring role-based
                                            access control (RBAC)</li>
                                        <li>Restricting access to
                                            specific features and data
                                        </li>
                                    </ul>
                                </li>

                                <li>
                                    <strong>JWT (JSON Web Tokens) for
                                        Authentication</strong>
                                    <ul>
                                        <li>Understanding JWT and its
                                            role in authentication</li>
                                        <li>Generating and verifying JWT
                                            tokens</li>
                                        <li>Using JWT for secure API
                                            authentication</li>
                                    </ul>
                                </li>

                                <li>
                                    <strong>Redux State Management for
                                        Authentication and
                                        Authorization</strong>
                                    <ul>
                                        <li>Managing user authentication
                                            state in Redux</li>
                                        <li>Updating user roles and
                                            permissions in the store
                                        </li>
                                        <li>Dispatching actions for
                                            login, logout, and role
                                            changes</li>
                                    </ul>
                                </li>

                                <li>
                                    <strong>Secure API Calls</strong>
                                    <ul>
                                        <li>Securing API endpoints with
                                            authentication checks</li>
                                        <li>Passing authentication
                                            tokens with API requests
                                        </li>
                                        <li>Handling unauthorized access
                                            errors</li>
                                    </ul>
                                </li>

                                <li>
                                    <strong>Advanced Topics
                                        (Optional)</strong>
                                    <ul>
                                        <li>Implementing password reset
                                            functionality</li>
                                        <li>Social media authentication
                                            (e.g., OAuth)</li>
                                        <li>Multi-factor authentication
                                            (MFA)</li>
                                    </ul>
                                </li>
                                <li>
                                    <strong>Introduction to
                                        Next.js</strong>
                                    <ul>
                                        <li>What is Next.js and its
                                            benefits</li>
                                        <li>Setting up a Next.js project
                                        </li>
                                        <li>Server-side rendering (SSR)
                                            and static site generation
                                            (SSG) with Next.js</li>
                                        <li>Creating pages and routing
                                            in Next.js</li>
                                        <li>Data fetching in Next.js
                                            apps</li>
                                    </ul>
                                </li>

                                <li>
                                    <strong>Advanced Next.js
                                        Features</strong>
                                    <ul>
                                        <li>Dynamic routes and route
                                            parameters</li>
                                        <li>Custom error handling in
                                            Next.js</li>
                                        <li>Internationalization (i18n)
                                            in Next.js</li>
                                        <li>API routes and serverless
                                            functions in Next.js</li>
                                    </ul>
                                </li>

                                <li>
                                    <strong>Introduction to
                                        Redux</strong>
                                    <ul>
                                        <li>What is Redux and its core
                                            concepts</li>
                                        <li>Setting up Redux in a React
                                            application</li>
                                        <li>Actions, reducers, and the
                                            store in functional
                                            components</li>
                                        <li>Using Redux DevTools for
                                            debugging</li>
                                    </ul>
                                </li>

                                <li>
                                    <strong>Redux Middleware</strong>
                                    <ul>
                                        <li>Thunk middleware for
                                            asynchronous actions</li>
                                        <li>Redux Toolkit for efficient
                                            state management</li>
                                        <li>Creating custom middleware
                                            for advanced use cases</li>
                                    </ul>
                                </li>

                                <li>
                                    <strong>Connecting Redux to
                                        React</strong>
                                    <ul>
                                        <li>Using the useSelector hook
                                            for accessing state</li>
                                        <li>Dispatching actions with the
                                            useDispatch hook</li>
                                        <li>Organizing Redux code with
                                            feature slices</li>
                                    </ul>
                                </li>

                                <li>
                                    <strong>Advanced Redux</strong>
                                    <ul>
                                        <li>Normalizing state for
                                            complex data structures</li>
                                        <li>Using selectors for
                                            efficient data retrieval
                                        </li>
                                        <li>Handling async data with
                                            Redux</li>
                                        <li>Optimizing Redux performance
                                            with reselect</li>
                                    </ul>
                                </li>

                                <li>
                                    <strong>Deployment and Production
                                        Considerations</strong>
                                    <ul>
                                        <li>Preparing a Redux-powered
                                            React app for production
                                        </li>
                                        <li>Hosting options (e.g.,
                                            Vercel, AWS, Netlify)</li>
                                        <li>Deployment strategies for
                                            Redux-powered React apps
                                        </li>
                                    </ul>
                                </li>
                                </ul>

                                <h6 class="mt-2">28. Developer Tools and Debugging</h6>
                                <ul class="left">
                                    <li>Developer Tools
                                        <ul class="left">
                                            <li>Elements Panel</li>
                                            <li>Console</li>
                                            <li> Network Panel</li>
                                            <li>Performance Panel</li>
                                            <li>Sources Panel</li>
                                            <li>Application Panel</li>
                                        </ul>
                                    </li>
                                </ul>
                                <ul class="left">
                                    <li>Common Debugging Techniques
                                        <ul class="left">
                                            <li>Console Logging
                                                <ul class="left">
                                                    <li>console.log()</li>
                                                    <li>console.warn()</li>
                                                    <li>console.error()</li>
                                                </ul>
                                            </li>
                                            <li>Breakpoints</li>
                                            <li>Watch Expressions and Scope Variables</li>
                                            <li>Network Debugging</li>
                                            <li>Mobile Device Emulation</li>
                                            <li>Memory and Performance Profiling</li>
                                        </ul>
                                    </li>
                                    <li>Best Practices for Effective Debugging
                                        <ul class="left">
                                            <li>Isolate the Problem</li>
                                            <li>Read Error Messages Carefully</li>
                                            <li>Use a Systematic Approach</li>
                                            <li>Take Advantage of Source Maps</li>
                                            <li>Inspect the Call Stack</li>
                                        </ul>
                                    </li>
                                    <li>Advanced Debugging Tips
                                        <ul class="left">
                                            <li>Use <code>debugger</code> Statements</li>
                                            <li>Error Handling</li>
                                            <li>Testing in Incognito Mode</li>
                                            <li>Analyze Runtime Error</li>
                                            <li>Custom Logging Solutions</li>
                                        </ul>
                                    </li>
                                    <li>Useful Tools and Extensions
                                        <ul class="left">
                                            <li>React DevTools</li>
                                            <li>Redux DevTools</li>
                                            <li>Lighthouse</li>
                                            <li>Postman</li>
                                            <li>Git</li>
                                        </ul>
                                    </li>
                                </ul>
                        </div>


                    </div>
                </div>
            </div>
            { /* Footer */}
            <footer id="footer-part">
                <div className="footer-top pt-40 pb-70">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="footer-about mt-40">
                                    <div className="logo">
                                        <a href="index.html">
                                            <img src="/images/sivasoft-logo.png"
                                                alt={keywords}
                                                className="sivasoft-logo" width={40} height={40} />
                                            <span className="sivasoft-footer-text"> Siva Soft </span>

                                        </a> </div> <p> SIVASOFT TECHNOLOGIES PRIVATE LIMITED (Training &
                                            Development) is an ISO 9001: 2015 certified company: No 1 Training Company with Dedicated Trainers and Professionals from Top MNCs like Amazon, Google, Microsoft, Infosys... </p>

                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="footer-address mt-40">
                                    <div className="footer-title pb-25">
                                        <h6> Contact Us </h6>
                                    </div>
                                    <ul>
                                        <li>
                                            <div className="icon">
                                                <i className="bi bi-geo-alt"> </i> </div>
                                            <div className="cont">
                                                <p> #417, Annapurna Block, Aditya Enclave, Near Ameerpet Metro, Hyderabad, India </p>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="icon">
                                                <i className="bi bi-whatsapp"> </i> </div>
                                            <div className="cont">
                                                <p> +91 63029 64834 </p>
                                                <p> +91 92481 53330 </p>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="icon">
                                                <i className="bi bi-envelope"> </i>
                                            </div> <div className="cont">
                                                <p> sivasoft @sivasoft.in </p>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="footer-copyright pt-10 pb-25">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="copyright text-md-left text-center pt-15">
                                    <p> &copy; 2012 - {new Date().getFullYear()} SIVASOFT TECHNOLOGIES PRIVATE LIMITED
                                        (Training & Development) is an ISO 9001: 2015 certified company.All Rights Reserved.Logo, Images, and Content are the trademark of their respective owners. {title} </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
            {/* WhatsApp Floating Button */}
            <a
                href="https://wa.me/916302964834"
                target="_blank" rel="noopener noreferrer"
                className="whatsapp-btn btn btn-success d-flex align-items-center justify-content-center"
                style={{ position: 'fixed', bottom: '20px', right: '20px', zIndex: 1000 }}
            >
                <BsWhatsapp size={24} color="white" />
            </a>
            {/* Include the Scroll to Top Button */}
            <ScrollToTopButton />
        </div>
    );
};

export default LocationPage;
