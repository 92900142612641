"use client"; // This directive makes this component a Client Component
import { BsFillSendArrowUpFill } from 'react-icons/bs';
const ScrollToTopButton = () => {
    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return (
        <button
            onClick={scrollToTop}
            className="btn btn-primary"
            style={{ position: 'fixed', bottom: '20px', left: '20px' }}>
            <BsFillSendArrowUpFill />
        </button>
    );
};

export default ScrollToTopButton;
